import React, { Component } from 'react'
import Error404 from 'components/Errors/Error404'

class Error extends Component {
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : null
        return { statusCode }
        
    }

    render() {
        // const {statusCode = 404} = this.props
        // const component = (statusCode == 404)? <Error404 />: <span>Something happened <Link to="/">Go home</Link></span>

        return (
            <Error404 />
        )
    }
}

export default Error